import type React from 'react';
import { $player, type PlayerStoreType } from './player';

export const ReactStoreHydrator = ({
  player,
  children,
}: {
  player: PlayerStoreType;
  children?: React.ReactNode;
}) => {
  $player.set(player);
  return children;
};
